.Tab{
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 15px 0;

    background-color: rgba(255, 255, 255, 0.705);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-top: 1px solid rgba(134, 134, 134, 0.2);

    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 10;
}
.tabbutton{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);

}

.tabbutton img{
    width: 25px;
    background-color: rgba(255, 255, 255, 0);
}

.tabbutton p{
    background-color: rgba(255, 255, 255, 0);
    color: #000;
    margin: 5px 0 0 0;
    font-size: 12px;

}