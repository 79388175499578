.main{
    height: 100vh;
    width: 100%;
    background-color: #f7f7f7;
    color: #fff;
}
.header{
    border-radius: 0 0 16px 16px;
    background-color: #fff ;

}

.header__mini{
    padding: 30px ;

    background-color: #1A1A1A ;
    background-color: #fff ;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.08);
    border:1px solid rgba(0, 0, 0, 0.15);
    background-color: #1f1d1d ;

}
.title{
    font-size: 22px;
    font-weight: bold;
    padding: 20px 30px;
    position: sticky;
    top: 0px;
    color: #000;
    background-color: rgba(255, 255, 255, 0.705);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid rgba(134, 134, 134, 0.2);
    z-index: 1000;
}

.info__cargo{
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #fff ;
    background-color: #1f1d1d ;

}


.namecargo{
    font-size: 27px;
    font-weight: bold;
    background-color: #fff ;
    color: #fcfcfc;
    background-color: #1f1d1d ;
}

.logo2{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #e0ddff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #111111;

    border: 4px solid #a3a3a3;
}

/*  */





.blocks__info{
    display: flex;
    flex-direction: column;
    margin: 0px 0 20px 0;
    background-color: #f7f7f7;
    background-color: #f7f7f7;
    width: 100%;
}

.section{
    padding: 20px 30px;
    background-color: #f7f7f7;
}

.text__block_info{
    background-color: #fff;
    color: #1A1A1A;
    font-weight: 500;
    font-size: 18px;
}

.block_info{
    background-color: #ffffff;
    background-color: rgb(255, 255, 255);
    width: 100%;
    padding: 25px 30px;
    margin: 7px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    border:1px solid rgba(0, 0, 0, 0.15);
}

.iconMain{
    width: 30px;
    background-color: #fff;
}
/* about */

.about{
    margin-bottom: 30px;
    background-color: #ffffff;
    color: #000;
    padding: 25px 20px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.08);
    border:1px solid rgba(0, 0, 0, 0.15);
}

.about h3{
    background-color: #ffffff;
    font-size: 20px;
    font-weight: 600;

}
.about p{
    background-color: #ffffff;
    margin: 20px 0 0 0;
    line-height: 18px;
    font-size: 16px;

}