.parcels{
    width: 100%;
    /* height: calc(100% - 72px - 62px); */
    padding: 20px 20px 100px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;


}

.statuses-client{
    width: 100%;
}
.parcels__status{
    width: 100%;
}

.status-client{
    width: 100%;
    background-color: #1f1d1d;
    padding: 20px 20px ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    margin-bottom: 10px;
}

.status__title{
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
}
.quantity__p{
    width: 25px;
    height: 25px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;


}
.quantity{
    color: #1f1d1d;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
 
}

.button__container{
    z-index: 1000;
    width: 100%;
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 72px;
    background-color: rgba(71, 71, 71, 0.02);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-top: 1px solid rgba(134, 134, 134, 0.2);
}


.add__track{
    background-color: #000;
    /* background-color: #001aff; */
    width: 100%;
    border-radius: 10px;
    font-size: 16px;
    padding: 20px 0;
    text-align: center;
    color: #fff;
    cursor: pointer;
    
}

.area{
    width: 100%;
    height: 100px;
}






.modalAdd{
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    width:80%;

    border: 1px solid #ccc;
    padding: 20px 30px 40px 30px;
    border-radius: 10px; /* Закругление углов */
    text-align: center;
    margin: 20px 0;
    color: #111111;
    background-color: #ffffff; /* Цвет фона */
    border-color: #007bff; /* Цвет границы при наведении */
  

    display: flex;
    flex-direction: column;
    z-index: 1000;
}

.modalAdd-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.modalAdd-header h2{
    font-size: 18px;
    font-weight: 500;
    color: #303030;
    text-align: left;

}


.overflow{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    background-color: rgba(48, 48, 48, 0.514);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-top: 1px solid rgba(134, 134, 134, 0.2);
    z-index: 100;    
}

.labelTrack{
    text-align: left;
    margin-top: 15px;
    font-size: 16px;
    font-weight: 400;
    
}
.input-trackAdd{
    margin-top: 10px;

    width: 100%;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 10px; /* Закругление углов */
    color: #111111;
    font-size: 16px;
    font-weight: 500;
    background-color: #fff;
    
    padding: 10px 15px;
}
    
.input-trackAdd:focus {
    border-color: #007bff; /* Цвет границы при наведении */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
}

.button__addTrack{
    background-color: #000;
    width: 100%;
    border-radius: 10px;
    font-size: 16px;
    padding: 15px 0;
    text-align: center;
    color: #fff;
    cursor: pointer;
    margin-top: 20px ;
}




/* status-detail */

.detail-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.ul-detail{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 10000;
}


.close-detail{
    width: 20px;
    height: 20px;
    background-image: url('../../assets/img/closeA.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition-duration: 0.3s;
}

.close-detail:hover{
    background-image: url('../../assets/img/closeB.png');

}

.ul-detail-border{
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    padding: 5px;
    border-radius: 12px;
    
    background-color: #dddddd;
    background-color: #ececec;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

    cursor: pointer;
    border: 1px solid #c2c2c2;
  

}


.li-container{
    padding: 20px;
    height: 100%;
    overflow: scroll;
}
.li-track-detail{
    width: 100%;
    background-color: #fdfdfd;
    
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
    background-color: #ececec;
}


.li-header{
    padding: 8px 10px ;
    background-color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.description-li{
    padding: 15px 6px 6px 10px;
    /* background-color: #cacaca; */
    
}
.date-li{
    display: flex;
    align-items: center;
    padding: 6px 6px 15px 10px;
    font-size: 12px;

}
.date-text{
    font-size: 12px;
    margin-right: 10px;
}




.confirm__container{
    width: 100%;
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0px;
    background-color: rgba(71, 71, 71, 0.02);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-top: 1px solid rgba(134, 134, 134, 0.2);
    z-index: 100000000;

}


.confirm__track{
    background-color: #001aff;
    width: 100%;
    border-radius: 10px;
    font-size: 16px;
    padding: 20px 0;
    text-align: center;
    color: #fff;
    cursor: pointer;
    
}


.archive-container{
    width: 100%;
    padding: 20px 20px ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 30px;
    background-color: rgba(71, 71, 71, 0.02);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(134, 134, 134);
    z-index: 1;
    
}

.archive-container p{
    color: #000;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
}

.archive-icon{
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../assets/img/archive.png');
}


.quantity-archive{
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
 
}


.quantity__p-archive{
    padding: 6px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    width: 25px;
    height: 25px;
}



.removeLiTrack{
    width: 20px;
    height: 20px;
    background-image: url("../../assets/img/trash.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}